var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.uploading),expression:"uploading"}],staticClass:"photo-wall single-media-wall"},[_c('div',{staticClass:"wall-content",style:(_vm.showInline ? 'display: flex;' : '')},[(
        _vm.isButtonStyle &&
        !_vm.useDefaultAvatar &&
        (!_vm.MediaData || JSON.stringify(_vm.MediaData) === '{}')
      )?[_c('el-button',{attrs:{"disabled":_vm.disabled,"type":"primary"},on:{"click":function($event){!_vm.disabled || JSON.stringify(_vm.MediaData) === '{}' ? (_vm.show = true) : ''}}},[_vm._v(_vm._s(_vm.addText))])]:_c('div',{staticClass:"img-content",class:_vm.disabled ? 'disabled-hover' : 'no-disabled-hover',style:({
        width: _vm.widthSize,
        height: _vm.heightSize,
        'aspect-ratio': !_vm.width && !_vm.height ? _vm.ratio.replace(':', '/') : '',
        'border-radius': _vm.BorderRadius,
        border:
          (!_vm.MediaData || JSON.stringify(_vm.MediaData) === '{}') &&
          !_vm.useDefaultAvatar
            ? '1px dashed #dcdfe6'
            : 'none',
        backgroundImage: _vm.useDefaultAvatar ? ("url('" + _vm.DefaultAvatar + "')") : '',
      }),on:{"click":function($event){!_vm.disabled || JSON.stringify(_vm.MediaData) === '{}' ? (_vm.show = true) : ''}}},[(_vm.UploadType === 'image' && _vm.MediaData)?_c('div',{staticClass:"photo",style:({
          backgroundColor: _vm.transparent ? 'unset' : '',
        })},[_c('el-image',{staticClass:"photo",style:({
            // width: widthSize,
            // height: heightSize,
            'border-radius': _vm.BorderRadius,
            backgroundColor: _vm.transparent ? 'unset' : '',
          }),attrs:{"src":_vm.fixImageUrl(_vm.MediaData),"fit":_vm.fit}}),(_vm.showMask && !_vm.disabled)?_c('div',{staticClass:"bg-mask flex-center",style:({ 'line-height': _vm.heightSize }),on:{"click":function($event){$event.stopPropagation();$event.preventDefault();}}},[(_vm.showCut)?_c('p',{staticClass:"el-icon el-icon-scissors",on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.handleCrop(_vm.MediaData)}}}):_vm._e(),(_vm.showPreview)?_c('p',{staticClass:"el-icon-zoom-in",on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.handlePreview.apply(null, arguments)}}}):_vm._e(),(_vm.showDelete)?_c('p',{staticClass:"el-icon-delete",on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.deleteMediaData.apply(null, arguments)}}}):_vm._e(),(_vm.directly)?_c('p',{staticClass:"el-icon-edit",on:{"click":function($event){$event.stopPropagation();$event.preventDefault();_vm.show = true}}}):_vm._e()]):_vm._e()],1):_vm._e(),(_vm.UploadType === 'video' && JSON.stringify(_vm.MediaData) !== '{}')?_c('div',[(!_vm.isLive)?_c('el-image',{staticClass:"photo",style:({
            width: _vm.widthSize,
            height: _vm.heightSize,
            'border-radius': _vm.BorderRadius,
          }),attrs:{"src":_vm.fixImageUrl(_vm.MediaData.thumbnail),"fit":_vm.fit}}):_vm._e(),_c('div',{staticClass:"video-mask flex-center"},[_c('p',{style:({ 'line-height': _vm.heightSize })},[_c('i',{staticClass:"el-icon-caret-right",on:{"click":function($event){$event.stopPropagation();return _vm.handlePlay(_vm.MediaData)}}})])]),(!_vm.disabled)?_c('div',{staticClass:"delete",on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.deleteMediaData.apply(null, arguments)}}},[_vm._v(" 删除视频 ")]):_vm._e()],1):_vm._e(),(_vm.UploadType === 'audio')?_c('div',[_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){_vm.show = true}}},[_vm._v(" "+_vm._s(_vm.MediaData.url || _vm.MediaData))])],1):_vm._e(),(
          !_vm.useDefaultAvatar &&
          (!_vm.MediaData || JSON.stringify(_vm.MediaData) === '{}')
        )?_c('div',{staticClass:"add"},[_c('div',{staticClass:"add-content"},[_c('i',{staticClass:"el-icon-plus",style:({
              fontSize:
                !_vm.width && !_vm.height
                  ? '25px'
                  : ((_vm.height / 2.5 > 25 ? 25 : _vm.height / 2.5) + "px"),
            })}),(_vm.addText)?_c('p',{staticClass:"info"},[_vm._v(_vm._s(_vm.addText))]):_vm._e()]),(_vm.encrypt)?_c('input',{attrs:{"accept":"image/*","type":"file"},on:{"change":_vm.onInputFileChange}}):_vm._e()]):_vm._e()]),_vm._t("info")],2),(!_vm.encrypt)?_c('media-selector',{attrs:{"type":_vm.UploadType,"ratio":_vm.ratio},on:{"select":_vm.handleMedia},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}}):_vm._e(),_c('video-viewer')],1)}
var staticRenderFns = []

export { render, staticRenderFns }